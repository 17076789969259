import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import JobContentService from "@/modules/content/services/JobContentService";

import i18n from "@/i18n";

export default class ContentGuard {
  static async findLatestContent(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestContent = await JobContentService.getLatestPendingApproval();

    if (latestContent.job_id) {
      return next({
        name: "ContentApproval",
        params: {
          id: latestContent.job_id,
        },
      });
    }

    DsFlashNotifier.info(i18n.global.t("modules.content.approvalBusy.message"));

    return next({
      name: "PendingContents",
    });
  }
}
