import { DsFlashNotifier } from "@devsalsa/vue-core";

import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from "vue-router";

import CreatorService from "@/modules/creator/services/CreatorService";

import i18n from "@/i18n";

export default class CreatorGuard {
  static async findLatestCreator(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestCreator = await CreatorService.getLatestPendingApproval();

    if (latestCreator.id) {
      return next({
        name: "CreatorApproval",
        params: {
          id: latestCreator.id,
        },
      });
    }

    setTimeout(() => {
      DsFlashNotifier.info(
        i18n.global.t("modules.creator.approvalBusy.message")
      );
    }, 150);

    return next({
      name: "PendingCreators",
    });
  }

  static async findLatestAmazonInfluencer(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestAmazonInfluencer =
      await CreatorService.getLatestAmazonPendingApproval();

    if (latestAmazonInfluencer.id) {
      return next({
        name: "AmazonInfluencerApproval",
        params: {
          id: latestAmazonInfluencer.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t(
        "modules.creator.amazonInfluencerApproval.approvalBusy.message"
      )
    );

    return next({
      name: "PendingAmazonInfluencers",
    });
  }

  static async findLatestPayment(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestPayment =
      await CreatorService.getLatestPaymentPendingApproval();

    if (latestPayment.id) {
      return next({
        name: "PaymentApproval",
        params: {
          id: latestPayment.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.creator.paymentApproval.approvalBusy.message")
    );

    return next({
      name: "PaymentsApproval",
    });
  }
}
