import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/",
    children: [
      {
        path: "/administrators",
        name: "Administrators",
        component: () =>
          import("@/core/modules/administrator/views/Administrators.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.administrators.meta",
          permission: Permission.ManageAdmin,
        },
      },
      {
        path: "/administrator/create",
        name: "CreateAdministrator",
        component: () =>
          import("@/core/modules/administrator/views/CreateAdministrator.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.createAdministrator.meta",
          permission: Permission.ManageAdmin,
        },
      },
      {
        path: "/administrator/:id/edit",
        name: "EditAdministrator",
        component: () =>
          import("@/core/modules/administrator/views/EditAdministrator.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.editAdministrator.meta",
          permission: Permission.ManageAdmin,
        },
      },
      {
        path: "/administrator/:id/edit-permissions",
        name: "EditAdministratorPermissions",
        component: () =>
          import(
            "@/core/modules/administrator/views/EditAdministratorPermissions.vue"
          ),
        beforeEnter: AuthGuard.withEditPermissions,
        meta: {
          title: "common.route.editAdministratorPermissions.meta",
          permission: Permission.ManageAdmin,
        },
      },
    ],
  },
];
