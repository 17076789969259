import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/wire-transfer",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "/wire-transfers",
        name: "WireTransfers",
        component: () => import("@/modules/transfers/views/WireTransfers.vue"),
        meta: {
          title: "common.route.wireTransfers.meta",
          permission: Permission.WireTransfer,
        },
        beforeEnter: AuthGuard.withToken,
      },
    ],
  },
];
