import type { PermissionList } from "@/core/modules/administrator/services/PermissionsService";
import { ApiService } from "@/core/shared/services/ApiService";

/**
 * @interface AccountToken
 * @memberOf AccountService
 * @property token {string} Token
 */
export interface AccountToken {
  token: string;
}

/**
 * @interface LoginFormData
 * @memberOf AccountService
 * @property email {string} Email
 * @property password {string} Password
 */
export interface LoginFormData {
  email: string;
  password: string;
}

/**
 * @interface AccountInfo
 * @memberOf AccountService
 * @property email {string} Email
 * @property name {string} Name
 * @property date_created {number} Date created
 * @property date_updated {number} Date updated
 * @property permissions {[]} Permission list
 */
export interface AccountInfo {
  id: number;
  email: string;
  name: string;
  date_created: number;
  date_updated: number;
  permissions: PermissionList;
}

/**
 * @interface AccountUpdated
 * @memberOf AccountService
 * @property updated {number} Updated (1 -> Yes, 0 -> No)
 */
export interface AccountUpdated {
  updated: number;
}

/**
 * @class AccountService
 */
export default class AccountService {
  /**
   * Get Information of Account
   * @endpoint /admin/me
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<AccountInfo>}
   */
  static getInfo(): Promise<AccountInfo> {
    return ApiService.get("/admin/me");
  }

  /**
   * Login Account
   * @endpoint /admin/login
   * @httpMethod POST
   * @param email {string} Email
   * @param password {string} Password
   * @throws {BadRequestApiServiceError}
   * @return {Promise<AccountToken>}
   */
  static login(email: string, password: string): Promise<AccountToken> {
    return ApiService.post("/admin/login", {
      email: email,
      password: password,
    });
  }

  /**
   * Logout Account
   * @endpoint /admin/logout
   * @httpMethod DELETE
   * @throws {BadRequestApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static logout(): Promise<AccountUpdated> {
    return ApiService.delete("/admin/logout");
  }
}
