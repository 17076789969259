import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/",
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/core/modules/profile/views/Profile.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: { title: "Profile" },
      },
    ],
  },
];
