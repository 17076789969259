<template>
  <svg viewBox="0 0 164 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2292_13083)">
      <path
        d="M70.2217 32.6336C69.0663 32.6336 68.0607 32.4121 67.2048 31.969C66.3633 31.5121 65.7071 30.8752 65.2365 30.0582C64.78 29.2413 64.5518 28.2652 64.5518 27.1298C64.5518 26.0082 64.78 25.039 65.2365 24.2221C65.7071 23.4052 66.3633 22.7752 67.2048 22.3321C68.0607 21.889 69.0663 21.6675 70.2217 21.6675C71.377 21.6675 72.3826 21.889 73.2385 22.3321C74.0943 22.7752 74.7504 23.4052 75.2069 24.2221C75.6776 25.039 75.913 26.0082 75.913 27.1298C75.913 28.2652 75.6776 29.2413 75.2069 30.0582C74.7504 30.8752 74.0943 31.5121 73.2385 31.969C72.3826 32.4121 71.377 32.6336 70.2217 32.6336ZM70.2217 30.2659C70.9633 30.2659 71.5482 30.0098 71.9762 29.4975C72.4183 28.9852 72.6394 28.1959 72.6394 27.1298C72.6394 26.0636 72.4183 25.2813 71.9762 24.7829C71.5482 24.2844 70.9633 24.0352 70.2217 24.0352C69.48 24.0352 68.888 24.2844 68.4458 24.7829C68.0179 25.2813 67.804 26.0636 67.804 27.1298C67.804 28.1959 68.0179 28.9852 68.4458 29.4975C68.888 30.0098 69.48 30.2659 70.2217 30.2659Z"
        fill="#111928"
      />
      <path
        d="M77.3579 32.405V21.875H80.5887V32.405H77.3579ZM77.2295 20.1511V17.2227H80.6957V20.1511H77.2295Z"
        fill="#111928"
      />
      <path
        d="M82.5872 32.4052V24.6167C82.5872 24.1736 82.573 23.7236 82.5444 23.2667C82.5159 22.7959 82.4731 22.3321 82.416 21.8752H85.5184L85.7538 23.8275H85.4971C85.8536 23.1352 86.36 22.6021 87.0162 22.2282C87.6722 21.8544 88.4354 21.6675 89.3055 21.6675C90.575 21.6675 91.5235 22.0275 92.1511 22.7475C92.7931 23.4536 93.114 24.5544 93.114 26.0498V32.4052H89.8832V26.1952C89.8832 25.4336 89.7406 24.8936 89.4553 24.5752C89.1699 24.2567 88.7421 24.0975 88.1715 24.0975C87.4583 24.0975 86.8878 24.319 86.4599 24.7621C86.0319 25.1913 85.818 25.7729 85.818 26.5067V32.4052H82.5872Z"
        fill="#111928"
      />
      <path
        d="M101.7 32.6333C100.83 32.6333 100.052 32.4256 99.3675 32.0102C98.6971 31.5948 98.2478 31.041 98.0195 30.3486H98.2549V32.4048H95.0669V17.1187H98.2977V23.8479H98.0195C98.262 23.1833 98.7184 22.6571 99.3889 22.2694C100.074 21.8679 100.844 21.6671 101.7 21.6671C102.655 21.6671 103.49 21.8887 104.203 22.3317C104.916 22.7748 105.472 23.4048 105.871 24.2217C106.286 25.0386 106.492 26.0217 106.492 27.171C106.492 28.3063 106.286 29.2825 105.871 30.0994C105.472 30.9163 104.909 31.5463 104.182 31.9894C103.468 32.4186 102.641 32.6333 101.7 32.6333ZM100.737 30.2656C101.464 30.2656 102.063 30.0163 102.534 29.5179C103.005 29.0194 103.24 28.2371 103.24 27.171C103.24 26.1048 103.005 25.3156 102.534 24.8033C102.063 24.291 101.464 24.0348 100.737 24.0348C99.9951 24.0348 99.3888 24.284 98.9182 24.7825C98.4617 25.281 98.2335 26.0633 98.2335 27.1294C98.2335 28.1956 98.4617 28.9848 98.9182 29.4971C99.3888 30.0094 99.9951 30.2656 100.737 30.2656Z"
        fill="#111928"
      />
      <path
        d="M107.923 32.4052V24.6583C107.923 24.2013 107.909 23.7375 107.88 23.2667C107.866 22.7959 107.83 22.3321 107.772 21.8752H110.875L111.239 24.949H110.747C110.889 24.2013 111.118 23.5852 111.432 23.1006C111.76 22.6159 112.159 22.2559 112.629 22.0206C113.115 21.7852 113.664 21.6675 114.277 21.6675C114.548 21.6675 114.763 21.6813 114.919 21.709C115.076 21.7229 115.233 21.7575 115.389 21.8129L115.368 24.5752C115.068 24.4506 114.812 24.3744 114.598 24.3467C114.398 24.3052 114.142 24.2844 113.828 24.2844C113.229 24.2844 112.73 24.3952 112.331 24.6167C111.945 24.8383 111.653 25.1636 111.453 25.5929C111.267 26.0221 111.175 26.5413 111.175 27.1506V32.4052H107.923Z"
        fill="#111928"
      />
      <path
        d="M120.209 32.6336C119.268 32.6336 118.44 32.4121 117.727 31.969C117.014 31.5259 116.458 30.8959 116.059 30.079C115.659 29.2621 115.459 28.279 115.459 27.1298C115.459 25.9944 115.659 25.0182 116.059 24.2013C116.458 23.3844 117.014 22.7613 117.727 22.3321C118.44 21.889 119.268 21.6675 120.209 21.6675C121.079 21.6675 121.856 21.8752 122.541 22.2906C123.24 22.6921 123.696 23.239 123.911 23.9313H123.675L123.932 21.8752H127.034C126.992 22.3321 126.949 22.7959 126.906 23.2667C126.878 23.7236 126.864 24.1736 126.864 24.6167V32.4052H123.653L123.632 30.4321H123.889C123.661 31.0967 123.204 31.6298 122.52 32.0313C121.835 32.4329 121.065 32.6336 120.209 32.6336ZM121.193 30.2659C121.935 30.2659 122.534 30.0098 122.99 29.4975C123.447 28.9852 123.675 28.1959 123.675 27.1298C123.675 26.0636 123.447 25.2813 122.99 24.7829C122.534 24.2844 121.935 24.0352 121.193 24.0352C120.451 24.0352 119.852 24.2844 119.396 24.7829C118.94 25.2813 118.711 26.0636 118.711 27.1298C118.711 28.1959 118.933 28.9852 119.375 29.4975C119.831 30.0098 120.437 30.2659 121.193 30.2659Z"
        fill="#111928"
      />
      <path
        d="M128.871 32.4052V24.6167C128.871 24.1736 128.857 23.7236 128.828 23.2667C128.8 22.7959 128.757 22.3321 128.699 21.8752H131.802L132.037 23.8275H131.78C132.137 23.1352 132.644 22.6021 133.3 22.2282C133.956 21.8544 134.719 21.6675 135.589 21.6675C136.859 21.6675 137.808 22.0275 138.434 22.7475C139.076 23.4536 139.397 24.5544 139.397 26.0498V32.4052H136.167V26.1952C136.167 25.4336 136.025 24.8936 135.739 24.5752C135.454 24.2567 135.026 24.0975 134.455 24.0975C133.742 24.0975 133.171 24.319 132.743 24.7621C132.316 25.1913 132.101 25.7729 132.101 26.5067V32.4052H128.871Z"
        fill="#111928"
      />
      <path
        d="M145.608 32.6333C144.667 32.6333 143.833 32.4117 143.105 31.9686C142.392 31.5256 141.828 30.8956 141.415 30.0786C141.016 29.2617 140.815 28.2786 140.815 27.1294C140.815 25.994 141.016 25.0179 141.415 24.201C141.814 23.384 142.378 22.761 143.105 22.3317C143.833 21.8887 144.667 21.6671 145.608 21.6671C146.464 21.6671 147.228 21.8679 147.898 22.2694C148.583 22.6571 149.039 23.1833 149.267 23.8479H148.989V17.1187H152.22V32.4048H149.032V30.3486H149.31C149.082 31.041 148.625 31.5948 147.941 32.0102C147.256 32.4256 146.478 32.6333 145.608 32.6333ZM146.571 30.2656C147.313 30.2656 147.912 30.0094 148.368 29.4971C148.825 28.9848 149.053 28.1956 149.053 27.1294C149.053 26.0633 148.825 25.281 148.368 24.7825C147.912 24.284 147.313 24.0348 146.571 24.0348C145.83 24.0348 145.223 24.284 144.753 24.7825C144.296 25.281 144.068 26.0633 144.068 27.1294C144.068 28.1956 144.296 28.9848 144.753 29.4971C145.223 30.0094 145.83 30.2656 146.571 30.2656Z"
        fill="#111928"
      />
      <path
        d="M158.229 32.6336C157.259 32.6336 156.375 32.5298 155.575 32.3221C154.777 32.1144 154.099 31.8236 153.542 31.4498L154.378 29.3729C154.933 29.7052 155.547 29.9682 156.217 30.1621C156.902 30.3559 157.58 30.4529 158.25 30.4529C158.878 30.4529 159.341 30.3559 159.641 30.1621C159.94 29.9682 160.09 29.7052 160.09 29.3729C160.09 29.0959 159.99 28.8813 159.79 28.729C159.605 28.5767 159.313 28.4659 158.913 28.3967L156.709 28.0021C155.782 27.8221 155.076 27.4898 154.591 27.0052C154.106 26.5206 153.863 25.8906 153.863 25.1152C153.863 24.4367 154.049 23.8413 154.42 23.329C154.805 22.8029 155.355 22.3944 156.068 22.1036C156.781 21.8129 157.615 21.6675 158.571 21.6675C159.384 21.6675 160.154 21.7644 160.881 21.9583C161.61 22.1521 162.229 22.4498 162.743 22.8513L161.866 24.8659C161.424 24.5475 160.911 24.2913 160.325 24.0975C159.755 23.9036 159.206 23.8067 158.678 23.8067C157.993 23.8067 157.501 23.9175 157.202 24.139C156.902 24.3467 156.753 24.6167 156.753 24.949C156.753 25.1983 156.838 25.4059 157.009 25.5721C157.195 25.7382 157.473 25.8629 157.844 25.9459L160.068 26.3406C161.024 26.5067 161.752 26.8182 162.251 27.2752C162.75 27.7321 163 28.3621 163 29.1652C163 29.899 162.8 30.5221 162.401 31.0344C162.002 31.5467 161.438 31.9413 160.71 32.2182C159.997 32.4952 159.17 32.6336 158.229 32.6336Z"
        fill="#111928"
      />
      <path
        d="M58.98 37.0365C58.6662 37.0365 58.3452 37.0157 58.0171 36.9742C57.6748 36.9327 57.3681 36.8565 57.0972 36.7457V34.3157C57.2968 34.3711 57.5036 34.4196 57.7176 34.4611C57.9173 34.5027 58.117 34.5234 58.3167 34.5234C58.7732 34.5234 59.1439 34.385 59.4293 34.108C59.7288 33.845 59.8786 33.3811 59.8786 32.7165V21.875H63.1094V32.8619C63.1094 34.2327 62.7742 35.2711 62.1038 35.9773C61.4476 36.6834 60.4064 37.0365 58.98 37.0365ZM59.7288 20.1511V17.2227H63.2164V20.1511H59.7288Z"
        fill="#111928"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.381477 31.9717C0 32.7442 0 33.7554 0 35.7778V42V56C7.73199 56 14 49.732 14 42L14 35.7778C14 33.7554 14 32.7442 13.6185 31.9717C13.283 31.2922 12.7475 30.7398 12.089 30.3936C11.3403 30 10.3602 30 8.4 30H5.6C3.63982 30 2.65972 30 1.91103 30.3936C1.25247 30.7398 0.717034 31.2922 0.381477 31.9717Z"
      fill="#111928"
    />
    <path
      d="M0 21C0 17.134 3.13401 14 7 14C10.866 14 14 17.134 14 21C14 24.866 10.866 28 7 28C3.13401 28 0 24.866 0 21Z"
      fill="#111928"
    />
    <path
      d="M14 28C14 20.268 20.268 14 28 14C35.732 14 42 20.268 42 28C42 35.732 35.732 42 28 42C20.268 42 14 35.732 14 28Z"
      fill="url(#paint0_radial_2292_13083)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 14C28 6.26801 21.732 0 14 0V14V28C14 35.732 20.268 42 28 42V28V14Z"
      fill="#FF1B60"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2292_13083"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28 25.5) rotate(90) scale(11.5 9.93566)"
      >
        <stop stop-color="#D61852" />
        <stop offset="1" stop-color="#FF1B60" />
      </radialGradient>
      <clipPath id="clip0_2292_13083">
        <rect
          width="106"
          height="20"
          fill="white"
          transform="translate(57 17)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoWithTextIcon",
});
</script>
