import { computed, ref } from "vue";

import { defineStore } from "pinia";

import type { Certificate } from "@/modules/certification/services/CertificationService.types";

export const useCertificationStore = defineStore("certification-store", () => {
  /**
   * Don't use this state, only be exposed for debugging
   */
  const certificates = ref<Certificate[]>([]);

  const hasCertificates = computed((): boolean => {
    return certificates.value && certificates.value.length > 0;
  });

  /**
   * @description
   * This method retrieve the first element and remove it from the certificates.
   * If you want to get the first element without touch the certificates you should
   * use the method {@link nextCertificate}.
   * @return Certificate | undefined
   */
  function retrieveCertificate(): Certificate | undefined {
    return certificates.value.shift();
  }

  /**
   * Add certificate to the certificates
   * @description
   * If the parameter is empty, we don't push the element in the certificates.
   * @param certificate
   * @return void
   */
  function queueCertificate(certificate: Certificate): void {
    if (certificate.id) {
      certificates.value.push(certificate);
    }
  }

  /**
   * This method get the first element from the certificates.
   * If you want to retrieve the first element and update the certificates you should
   * use the method {@link retrieveCertificate}.
   * @return Certificate | undefined
   */
  function nextCertificate(): Certificate | undefined {
    return certificates.value ? certificates.value[0] : undefined;
  }

  return {
    nextCertificate,
    retrieveCertificate,
    queueCertificate,
    hasCertificates,
    certificates,
  };
});
