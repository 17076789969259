import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/",
    children: [
      {
        path: "/coupons",
        name: "Coupons",
        component: () => import("@/modules/coupons/views/Coupons.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.coupons.meta",
        },
      },
      {
        path: "/coupon/create",
        name: "CreateCoupon",
        component: () => import("@/modules/coupons/views/CreateCoupon.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.coupons.create.meta",
        },
      },
      {
        path: "/coupon/:id/edit",
        name: "EditCoupon",
        component: () => import("@/modules/coupons/views/EditCoupon.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.coupons.edit.meta",
        },
      },
      {
        path: "/coupon/:id",
        name: "CouponSummary",
        component: () => import("@/modules/coupons/views/CouponSummary.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.coupons.edit.meta",
        },
      },
    ],
  },
];
