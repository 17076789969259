import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/ip-address",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "",
        name: "IpSearch",
        component: () => import("@/modules/ip-address/views/IpSearch.vue"),
        meta: {
          title: "common.route.ipSearch.meta",
          permission: Permission.ManageCreator,
        },
        beforeEnter: AuthGuard.withToken,
      },
    ],
  },
];
