import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import TikTokAdService from "@/modules/tiktok-ad/services/TikTokAdService";

import i18n from "@/i18n";

export default class TikTokAdGuard {
  static async findLatestTikTokAd(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestTikTokAd = await TikTokAdService.getLatestPendingApproval();

    if (latestTikTokAd.job_id) {
      return next({
        name: "TikTokAdApproval",
        params: {
          id: latestTikTokAd.job_id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.tikTokAdApproval.approvalBusy.message")
    );

    return next({
      name: "PendingTikTokAds",
    });
  }
}
