import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

import ContentGuard from "@/modules/content/helpers/ContentGuard";

export default [
  {
    path: "/content",
    children: [
      {
        path: "approval",
        name: "ContentLatestApproval",
        //This "component" should exist to avoid type check error
        component: () => import("@/modules/content/views/ContentApproval.vue"),
        beforeEnter: ContentGuard.findLatestContent,
        meta: {
          title: "common.route.contents.meta",
          permission: Permission.JobContentApproval,
        },
      },
      {
        path: ":id/approval",
        name: "ContentApproval",
        component: () => import("@/modules/content/views/ContentApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.contents.meta",
          permission: Permission.JobContentApproval,
        },
      },
      {
        path: "/contents/pending",
        name: "PendingContents",
        component: () => import("@/modules/content/views/PendingContents.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.content.pendingContents.meta.title",
          permission: Permission.JobContentApproval,
        },
      },
    ],
  },
];
