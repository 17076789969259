import type { FirebaseError } from "@firebase/util";

export class FirebaseErrorTranslator {
  static translate(error: FirebaseError): string {
    if (error.code === "auth/popup-closed-by-user") {
      return "";
    }

    return error.message;
  }
}
