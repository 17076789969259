import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

import CompanyGuard from "@/modules/company/helpers/CompanyGuard";

export default [
  {
    path: "/company",
    children: [
      {
        path: "/companies",
        name: "Companies",
        component: () => import("@/modules/company/views/TheCompanies.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.company.companies.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: "/companies/top",
        name: "TopCompanies",
        component: () => import("@/modules/company/views/TopCompanies.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.company.topCompanies.meta.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: ":id/edit",
        name: "EditCompany",
        component: () => import("@/modules/company/views/CompanyEdit.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.company.editCompany.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: ":id",
        name: "CompanySummary",
        component: () => import("@/modules/company/views/CompanySummary.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.company.summary.meta.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: ":id/wallet",
        name: "CompanyWallet",
        component: () => import("@/modules/company/views/CompanyWallet.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.wallet.company.meta.title",
          permission: Permission.ManageAdmin,
        },
      },
      {
        path: "withdrawals-approval",
        name: "WithdrawalsApproval",
        component: () =>
          import("@/modules/company/views/WithdrawalsApproval.vue"),
        meta: {
          title: "common.route.companyWithdrawals.meta.title",
          permission: Permission.ManageCompany,
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: "withdrawal-approval",
        name: "WithdrawalLatestApproval",
        beforeEnter: CompanyGuard.findLatestWithdrawal,
        //This "component" should exist to avoid type check error
        component: () =>
          import("@/modules/company/views/WithdrawalApproval.vue"),
        meta: {
          title: "common.route.companyWithdrawalApproval.meta.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: ":id/withdrawal-approval",
        name: "WithdrawalApproval",
        component: () =>
          import("@/modules/company/views/WithdrawalApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.companyWithdrawalApproval.meta.title",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: "watermarks",
        name: "Watermarks",
        component: () => import("@/modules/company/views/TheWatermarks.vue"),
        meta: {
          title: "common.route.companyWatermarks.meta",
          permission: Permission.ManageCompany,
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: "watermark-approval",
        name: "WatermarkLatestApproval",
        beforeEnter: CompanyGuard.findLatestWatermark,
        //This "component" should exist to avoid type check error
        component: () =>
          import("@/modules/company/views/TheWatermarkApproval.vue"),
        meta: {
          title: "common.route.companyWatermarkApproval.meta",
          permission: Permission.ManageCompany,
        },
      },
      {
        path: ":id/watermark-approval",
        name: "WatermarkApproval",
        component: () =>
          import("@/modules/company/views/TheWatermarkApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.companyWatermarkApproval.meta",
          permission: Permission.ManageCompany,
        },
      },
    ],
  },
];
