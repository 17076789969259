import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/job/",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: ":jobId(\\d+)",
        name: "JobSummary",
        component: () => import("@/modules/job/views/JobSummary.vue"),
        meta: {
          title: "modules.job.summary.meta",
        },
      },
      {
        path: "/jobs",
        name: "Jobs",
        component: () => import("@/modules/job/views/Jobs.vue"),
        meta: {
          title: "common.route.jobs.meta",
          permission: Permission.JobData,
        },
      },
      {
        path: "/jobs/mediation",
        name: "JobMediations",
        component: () => import("@/modules/job/views/JobMediations.vue"),
        meta: {
          title: "modules.job.jobMediations.meta.title",
          permission: Permission.JobMediation,
        },
      },
      {
        path: ":id/mediation",
        name: "JobMediation",
        component: () => import("@/modules/job/views/JobMediation.vue"),
        meta: {
          title: "modules.job.jobMediation.meta.title",
          permission: Permission.JobMediation,
        },
      },
      {
        path: "/jobs/late",
        name: "LateJobs",
        component: () => import("@/modules/job/views/LateJobs.vue"),
        meta: {
          title: "common.route.lateJobs.meta.title",
          permission: Permission.JobData,
        },
      },
    ],
  },
];
