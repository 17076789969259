import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CompanyService from "@/modules/company/services/CompanyService";

import i18n from "@/i18n";

export default class CompanyGuard {
  static async findLatestWithdrawal(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestWithdrawal =
      await CompanyService.getLatestWithdrawalPendingApproval();

    if (latestWithdrawal.id) {
      return next({
        name: "WithdrawalApproval",
        params: {
          id: latestWithdrawal.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.company.withdrawals.approvalBusy.message")
    );

    return next({
      name: "WithdrawalsApproval",
    });
  }

  static async findLatestWatermark(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestWatermark =
      await CompanyService.getLatestWatermarkPendingApproval();

    if (latestWatermark.id) {
      return next({
        name: "WatermarkApproval",
        params: {
          id: latestWatermark.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.company.watermark.approvalBusy.message")
    );

    return next({
      name: "Watermarks",
    });
  }
}
