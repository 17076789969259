<template>
  <footer
    class="bottom-0 relative left-0 w-full bg-gray-50 lg:bg-white px-4 lg:px-8 py-4 h-32 sm:h-16 font-normal text-left flex flex-wrap items-center justify-between justify-start"
  >
    <div class="w-full sm:w-7/12 xl:w-8/12 2xl:w-9/12">
      <div class="text-gray-500 text-sm lg:text-base">
        © {{ date }} JoinBrands.com
        {{ $t("core.shared.components.layout.footer.rights") }}
        {{ version }}
      </div>
    </div>
    <div>
      <ExternalFooterLink
        href="https://joinbrands.com/privacy"
        target="_blank"
        class="block sm:inline-block mr-5"
        rel="nofollow"
      >
        {{ $t("core.shared.components.layout.footer.privacy") }}
      </ExternalFooterLink>
      <ExternalFooterLink
        href="https://joinbrands.com/terms"
        target="_blank"
        rel="nofollow"
      >
        {{ $t("core.shared.components.layout.footer.terms") }}
      </ExternalFooterLink>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

export default defineComponent({
  name: "Footer",
  components: { ExternalFooterLink },
  data() {
    return {
      date: new Date().getFullYear(),
      version: `v${import.meta.env.PACKAGE_VERSION}`,
    };
  },
});
</script>
