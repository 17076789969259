import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CampaignService from "@/modules/campaign/services/CampaignService";

import i18n from "@/i18n";

export default class CampaignGuard {
  static async findLatestCampaign(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestCampaign = await CampaignService.getLatestPendingApproval();

    if (latestCampaign.id) {
      return next({
        name: "CampaignApproval",
        params: {
          id: latestCampaign.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.campaign.approvalBusy.message")
    );

    return next({
      name: "PendingCampaigns",
    });
  }

  static async findLatestCampaignEdition(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestCampaignDraft =
      await CampaignService.getLatestPendingEditionApproval();

    if (latestCampaignDraft.campaign.id) {
      return next({
        name: "CampaignEditionApproval",
        params: {
          id: latestCampaignDraft.campaign.id,
        },
      });
    }

    DsFlashNotifier.info(
      i18n.global.t("modules.campaign.approvalBusy.message")
    );

    return next({
      name: "CampaignDrafts",
    });
  }
}
