import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/",
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/core/modules/account/views/Login.vue"),
        meta: {
          title: "core.modules.account.login.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutToken,
      },
      {
        path: "logout",
        name: "Logout",
        component: () => import("@/core/modules/account/views/Logout.vue"),
        meta: {
          title: "core.modules.account.logout.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withToken,
      },
    ],
  },
];
