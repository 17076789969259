import Datetime from "@/core/shared/helpers/Datetime";

import type { App } from "vue";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * The locale datetime of localization.
     *@methods format(unixTime: number, key: string [default => short])
     */
    $datetime: typeof Datetime;
  }
}

export default {
  install(app: App) {
    // configure the app
    app.config.globalProperties.$datetime = Datetime;
  },
};
