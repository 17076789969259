import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/portfolio",
    children: [
      {
        path: "/portfolios/pending",
        name: "PortfoliosPendingApproval",
        component: () =>
          import("@/modules/portfolio/views/PortfolioReports.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.portfolioReports.meta.title",
          permission: Permission.ManageCreator,
        },
      },
    ],
  },
];
