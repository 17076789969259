import { ApiService } from "@/core/shared/services/ApiService";

import type {
  ContentJobInfo,
  ContentUpdated,
  JobContentDownload,
  JobContentResponse,
  JobsContentsList,
  SearchJobContentFilters,
} from "@/modules/content/services/JobContentService.types";

/**
 * @class JobContentService
 */
export default class JobContentService {
  /**
   * Post job submit content moderation
   * @endpoint /admin/jobs/${jobId}/submit-moderation
   * @httpMethod POST
   * @param jobId {number} Job id
   * @param data {FormData} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ContentUpdated>}
   */
  static submit(jobId: number, data: FormData): Promise<ContentUpdated> {
    return ApiService.post(`/admin/jobs/${jobId}/submit-moderation`, data);
  }

  /**
   * Ban a creator for bad content
   * @endpoint /admin/jobs/${jobId}/ban-creator
   * @httpMethod PUT
   * @param jobId {number} Job id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ContentUpdated>}
   */
  static suspend(jobId: number): Promise<ContentUpdated> {
    return ApiService.put(`/admin/jobs/${jobId}/ban-creator`);
  }

  /**
   * Get the list of pending content for a job
   * @endpoint /admin/jobs/${jobId}/job-contents
   * @httpMethod GET
   * @param jobId {number} Job id
   * @param filters
   * @throws {BadRequestApiServiceError}
   * @return {Promise<JobContentResponse>}
   */
  static list(
    jobId: number,
    filters?: SearchJobContentFilters
  ): Promise<JobContentResponse> {
    return ApiService.post(`/admin/jobs/${jobId}/job-contents`, filters);
  }

  /**
   * Get the list of jobs with content pending admin approval
   * @endpoint /admin/jobs/content-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<JobsContentsList>}
   */
  static listPendingApproval(): Promise<JobsContentsList> {
    return ApiService.get(`/admin/jobs/content-approvals`);
  }

  /**
   * Get latest data of a job content on the onboarding approval
   * @endpoint /admin/jobs/latest-job-id-with-content-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ContentJobInfo>}
   */
  static getLatestPendingApproval(): Promise<ContentJobInfo> {
    return ApiService.get(`/admin/jobs/latest-job-id-with-content-approvals`);
  }

  /**
   * Mark a content as inspiring
   *
   * @endpoint /admin/job-contents/${contentId}/send-valuable-content-information
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   * @return {Promise<JobsContentsList>}
   */
  static markAsInspiring(contentId: number): Promise<ContentUpdated> {
    return ApiService.post(
      `/admin/job-contents/${contentId}/send-valuable-content-information`
    );
  }

  /**
   * @endpoint /admin/job-contents/${url}/download
   * @httpMethod GET
   * @param url
   */
  static download(url: string): Promise<JobContentDownload> {
    return ApiService.get(`/admin/job-contents/${url}/download`);
  }
}
