import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CertificationService from "@/modules/certification/services/CertificationService";

import i18n from "@/i18n";
import { useCertificationStore } from "@/modules/certification/store/CertificationStore";

export default class CertificationGuard {
  static async findLatestCertification(
    to: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const { queueCertificate } = useCertificationStore();

    const certificationId = Number(to.params.id);
    const latestCertificate =
      await CertificationService.getLatestPendingApproval(certificationId);

    if (latestCertificate.id) {
      //If we have a certificate, we store it
      queueCertificate(latestCertificate);
      return next({
        name: "CertificationApproval",
        params: {
          id: certificationId,
          creatorId: latestCertificate.creator_id,
        },
      });
    }

    setTimeout(() => {
      DsFlashNotifier.info(
        i18n.global.t("modules.certification.approvalBusy.message")
      );
    }, 150);

    return next({
      name: "PendingCertifications",
      params: {
        certificationId: certificationId,
      },
    });
  }
}
