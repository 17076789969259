import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/chats/flagged",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "",
        name: "FlaggedChats",
        component: () => import("@/modules/chat/views/FlaggedChats.vue"),
        meta: {
          title: "common.route.flaggedChats.meta.title",
        },
        beforeEnter: AuthGuard.withToken,
      },
    ],
  },
];
