import { ApiService } from "@/core/shared/services/ApiService";

import type {
  PendingApprovalTikTokAdsResponse,
  TikTokAd,
  TikTokAdsResponse,
  TikTokAdUpdated,
} from "@/modules/tiktok-ad/services/TikTokAdService.types";

export default class TikTokAdService {
  /**
   * Get TikTok ad data
   * @endpoint /admin/tiktok-ads/{tikTokAdId}
   * @httpMethod GET
   * @param tikTokAdId {number} TikTok ad id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Campaign>}
   */
  static get(tikTokAdId: number): Promise<TikTokAd> {
    return ApiService.get(`/admin/tiktok-ads/${tikTokAdId}`);
  }

  /**
   * Approve TikTok ad
   * @endpoint /admin/tiktok-ads/{campaignId}/approve
   * @httpMethod PUT
   * @param tikTokAdId {number} TikTok ad id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<TikTokAdUpdated>}
   */
  static approve(tikTokAdId: number): Promise<TikTokAdUpdated> {
    return ApiService.put(`/admin/tiktok-ads/${tikTokAdId}/approve`);
  }

  /**
   * Reject TikTok ad
   * @endpoint /admin/tiktok-ads/{tikTokAdId}/deny
   * @httpMethod PUT
   * @param tikTokAdId {number} Campaign id
   * @param reject_reason {string} Reason
   * @throws {ApiServiceError}
   * @return {Promise<TikTokAdUpdated>}
   */
  static reject(
    tikTokAdId: number,
    reject_reason: string
  ): Promise<TikTokAdUpdated> {
    return ApiService.put(`/admin/tiktok-ads/${tikTokAdId}/deny`, {
      reject_reason: reject_reason,
    });
  }

  /**
   * Get a list of TikTok ads
   *
   * @param page
   * @param rows
   */
  static list(page: number, rows: number): Promise<TikTokAdsResponse> {
    return ApiService.post(`/admin/search/tiktok-ads/${page}/${rows}`);
  }

  /**
   * Get TikTok ads pending approval
   * @endpoint /admin/tiktok-ads/need-approval/${page}/${rows}
   * @httpMethod GET
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static listPendingApproval(
    page = 0,
    rows = 25
  ): Promise<PendingApprovalTikTokAdsResponse> {
    return ApiService.get(`/admin/tiktok-ads/need-approval/${page}/${rows}`);
  }

  /**
   * Get latest data of a TikTok Ad on the onboarding approval
   * @endpoint /admin/tiktok-ads/latest-need-approval
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<TikTokAd>}
   */
  static getLatestPendingApproval(): Promise<TikTokAd> {
    return ApiService.get(`/admin/tiktok-ads/latest-need-approval`);
  }
}
