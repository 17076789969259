<template>
  <header
    class="flex items-center flex-shrink-0 fixed z-10 w-full top-0 bg-white border-b h-16"
  >
    <div class="flex-shrink-0 px-4 lg:px-8">
      <RouterLink
        class="flex items-center block w-full"
        :to="{ name: 'Dashboard' }"
      >
        <slot>
          <img
            src="../../../assets/img/logo.svg"
            alt="JoinBrands"
            class="h-11 w-8"
            width="36"
            height="36"
          />
        </slot>
      </RouterLink>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UnauthenticatedNavbar",
});
</script>
