import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/campaign/",
    children: [
      {
        path: "recurring/:id(\\d+)",
        name: "RecurringCampaignSummary",
        component: () =>
          import(
            "@/modules/recurring-campaign/views/RecurringCampaignSummary.vue"
          ),
        meta: {
          title: "modules.recurringCampaigns.meta.title",
        },
      },
      {
        path: "/recurring-campaigns",
        name: "RecurringCampaigns",
        component: () =>
          import("@/modules/recurring-campaign/views/RecurringCampaigns.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.recurringCampaigns.meta.title",
          permission: Permission.RecurringCampaign,
        },
      },
    ],
  },
];
