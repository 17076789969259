import "@/assets/scss/index.scss";

import { createApp } from "vue";

import { createPinia } from "pinia";

import { DsNotifier } from "@devsalsa/vue-core";

import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";
import Datetime from "@/core/shared/plugins/Datetime";
import Grid from "@/core/shared/plugins/Grid";
import Network from "@/core/shared/plugins/Network";

import * as Sentry from "@sentry/vue";

import App from "@/App.vue";
import i18n from "@/i18n";
import router from "@/router";

const app = createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(Network)
  .use(Grid)
  .use(Datetime);

app.config.performance = true;
app.config.errorHandler = (error: unknown, instance, info) => {
  ErrorHandler.handle(error);
  if (import.meta.env.VITE_APP_ENVIRONMENT === "development") {
    DsNotifier.sendError({
      duration: 0,
      enableClose: false,
      position: "top-0 right-0",
      overlay: true,
      title: (error as Error).name,
      message: (error as Error).message,
    });
    console.error("VueJS error handler", error, instance, info);
  } else {
    console.log("VueJS error handler", error, instance, info);
  }
};
if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.5,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      import.meta.env.VITE_APP_SENTRY_ORIGIN,
      /^\//,
    ],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    release: `com.joinbrands.web.admin@${import.meta.env.PACKAGE_VERSION}`,
  });
}

app.use(router).mount("#app");

export default app;
