import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/videos",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "",
        name: "Videos",
        component: () => import("@/modules/video/views/Videos.vue"),
        meta: {
          title: "common.route.videos.meta",
          permission: Permission.ManageVideo,
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: "/video/:id",
        name: "VideoContent",
        component: () => import("@/modules/video/views/ContentVideo.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.content.pendingContents.meta.title",
          permission: Permission.ManageVideo,
        },
      },
    ],
  },
];
