import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/job/",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "recurring/:jobId(\\d+)",
        name: "RecurringJobSummary",
        component: () =>
          import("@/modules/recurring-job/views/RecurringJobSummary.vue"),
        meta: {
          title: "modules.job.recurringJobs.summary.meta",
        },
      },
      {
        path: "/recurring-jobs",
        name: "RecurringJobs",
        component: () =>
          import("@/modules/recurring-job/views/RecurringJobs.vue"),
        meta: {
          title: "common.route.recurringJobs.meta",
          permission: Permission.RecurringCampaign,
        },
      },
    ],
  },
];
